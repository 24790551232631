@tailwind base;
@tailwind components;
@tailwind utilities;

.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  display: none;
}

.pdf-scrollbar {
  scrollbar-color: rgba(0, 0, 0, 0.5);
  scrollbar-width: thin;
}

/* Global */
.pdf-scrollbar::-webkit-scrollbar {
  width: 8px;
  margin-right: 1px;
}

/* Ascenseur */
.pdf-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}
